// src/pages/Home.js
import React from 'react';
import './Home.css'; // Import the CSS file

function Home() {
  return (
    <div className="home">
      <div className="container">
        <h1>Cardano's Orderbook</h1>
        <p>Welcome to Orderbook, your decentralized and permissionless exchange for assets and tokens on the Cardano blockchain. We operate entirely through a single smart contract, eliminating the need for third parties, providing true decentralization, and ensuring secure and transparent trading.</p>
        <h2>What is Orderbook?</h2>
        <p>Orderbook is a revolutionary decentralized exchange (DEX) running directly on the Cardano ledger as a single smart contract. We're not reliant on intermediaries, ensuring a trustless and open trading environment.</p>
        <h2>Why Orderbook Stands Out</h2>
        <p>Unlike most DEXs, which often use Automated Market Makers (AMMs) which in turn has to employ third-party entity "Batchers". Orderbook operates directly on the Cardano ledger as a single smart contract. This eliminates the need for intermediaries, enhancing decentralization and security.</p>
        <p>Even though some DEXs open source their batching process, it is still susceptible to frontrunning. Orderbook sidesteps this issue by utilizing the Cardano blockchain's innate decentralization.</p>
        <h2>What to Expect from Orderbook</h2>
        <p>Orderbook achieves what web2 exchanges (e.g, Binance, Coinbase) strive to accomplish, but with two key differences, decentralization and speed. While CEXs have rapid response times of 20 to 40 milliseconds for ledger updates, Orderbook leverages Cardano's ledger, resulting in an average of 20 second refresh rate.</p>
        <p>This is not a shortcoming but a reflection of Cardano's decentralized nature. The faster Cardano evolves, the quicker Orderbook becomes. Hence, during high-traffic moments, some transactions may fail as multiple users attempt to claim the same order within a single block time. This challenge decreases with smaller block times, such the case with Web2-based exchanges.</p>
        <p>A second key point is the reduced fliexibility to introduce as many inputs compaed to normal transaction. One crucial aspect to highlight is the inefficiency in IOHK's design of the Extended UTXO (EUTXO) model regarding smart contract utlization. This inefficiency becomes evident when we consider the requirement to include or reference a smart contract for each input, even if multiple inputs share the same smart contract. Such a suboptimal approach impedes a fundamental advantage of the UTXO model, which is the ability to utilize numerous inputs and outputs within a single transaction. A more effective approach would involve referencing the smart contract only once, akin to how standard signing keys are referenced.</p>
        <h2>The Role of This Website</h2>
        <p>This website serves as an interactive tool for users to seamlessly interact with the Orderbook smart contract. Anyone can create their own transactions if they prefer to interact with the smart contract directly, offering complete flexibility. We encourage other DEXs and projects to build their own interfaces for the Orderbook smart contract, fostering a collaborative ecosystem.</p>
      </div>
    </div>
  );
}

export default Home;
