import React from 'react';
import './WalletMenu.css';

const WalletMenu = ({ setWalletAPI, connectedWalletAPI,setWalletName,orders,setMyOrders,initalizeMyOrders,Cookies,onClose}) => {
  let walletAPI,myOrders;
  const isNamiAvailable = window.cardano.nami !== undefined;
  const isEternlAvailable = window.cardano.eternl !== undefined;
  const isFlintAvailable = window.cardano.flint !== undefined;




  return (
    <div className="backdrop" onClick={onClose}>
    <div className="wallet-menu">
      <h2>Connect Wallet</h2>
      <ul className="wallet-list">
        {isNamiAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.nami.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Nami');setMyOrders(await initalizeMyOrders(walletAPI,orders));Cookies.set('lastConnectedWalletName','nami',{expires:1000})}}>
                <img src={window.cardano.nami.icon} alt="Nami Icon" className="wallet-icon" />
                Nami
            </button>
          </li>
        )}
        {isEternlAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.eternl.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Eternl');setMyOrders(await initalizeMyOrders(walletAPI,orders));Cookies.set('lastConnectedWalletName','eternl',{expires:1000})}}>
                <img src={window.cardano.eternl.icon} alt="Eternl Icon" className="wallet-icon" />
                Eternl
            </button>
          </li>
        )}
        {isFlintAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.flint.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Flint');setMyOrders(await initalizeMyOrders(walletAPI,orders));Cookies.set('lastConnectedWalletName','flint',{expires:1000})}}>
                <img src={window.cardano.flint.icon} alt="Flint Icon" className="wallet-icon" />
                Flint
            </button>
          </li>
        )}
      </ul>
    </div>
    </div>
  );
};

export default WalletMenu;
